import * as React from "react"
import { Link } from "gatsby"
import { useState, useEffect } from "react"
import { CheckmarkSharp } from 'react-ionicons'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ContinueCheckout from '../components/ContinueCheckout.js'
import EndCheckout from '../components/EndCheckout.js'
import Progress from '../components/Progress.js'
import { navigate } from "gatsby"
import { useContext } from 'react'
import { CheckoutContext } from "../context/CheckoutContext"
import CountryCode from '../components/CountryCode.js'

const PhoneField = (props) => {
  const [countryCode, setStateCountryCode] = useState('371');
  const [phone, setPhone] = useState('');
  const phoneField = React.useRef(null);

  const _setPhone = (e) => {
    // console.log(e.target.value)
    setPhone(e.target.value)
  }
  const setCountryCode = (e) => {
    // console.log(e.target.value)
    setStateCountryCode(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    props.onSubmit(phone ? `${countryCode}${phone}` : null)
  }

  useEffect(() => {
    phoneField.current.focus()
  }, [])

  return (
    <>
      <form onSubmit={handleSubmit} class="is-max-mobile">
        <label class="label">Norādi savu mobilā telefona numuru, lai pieslēgtos</label>

        <div class="field has-addons">
          <div class="control  ">
            <span class="select ">
              <CountryCode value={countryCode} handleChange={setCountryCode} />
              {/* <select onChange={_setCountryCode} value={countryCode ? countryCode : ''}>
                <option value="371">+371</option>
                <option value="372">+372</option>
                <option value="373">+373</option>
              </select> */}
            </span>
          </div>
          <div class={props.isLoading ? "control is-expanded is-loading " : "control is-expanded "}>
            <input ref={phoneField} class="input " required type="tel" placeholder="Telefona numurs" value={phone ? phone : ''} onChange={_setPhone} />
          </div>
        </div>
          <div class=" ">
            <button type="submit" ref={props.phoneFormRef} class={props.isLoading ? "button is-loading is-primary" : "button is-primary"}>
              Apstiprināt
            </button>
          </div>
      </form>


      {/* <p class="mt-2">Šo telefona numuru varēsi izmantot, lai pieslēgtos Heyday kontam.</p> */}
    </>
  )
}

const CodeField = (props) => {
  const context = useContext(CheckoutContext)

  const codeForm = React.useRef(null);
  const codeField = React.useRef(null);
  const [code, setCode] = useState(null);

  const _setCode = (e) => {
    setCode(e.target.value)
  }

  const _onSubmit = (e) => {
    e.preventDefault()
    props.onSubmit(code)
  }

  useEffect(() => {
    if (code) {
      if (code.length == 6) {
        codeForm.current.reportValidity()
        props.onSubmit(code)
      }
    }
  }, [code]);

  useEffect(() => {
    codeField.current.focus()
  }, [])

  return (
    <>

      <form onSubmit={_onSubmit} ref={codeForm} class="is-max-mobile">
        <label class="label">Apstiprinājuma kods</label>

        <div class="field has-addons">
          <div class={props.isLoading ? "is-loading is-large control is-expanded" : "control is-large is-expanded"}>
            <input ref={codeField} class="input is-large" value={code ? code : ''} required type="text" autoComplete="one-time-code" inputMode="numeric" pattern="[0-9]*" placeholder="000000" minLength="6" maxLength="6" onChange={_setCode} />
          </div>
          <div class="control is-hidden">
            <button ref={props.phoneFormRef} type="submit" class={props.isLoading ? "button is-loading is-large" : "button is-large"}>
              Apstiprināt
          </button>
          </div>
        </div>
        <p>Uz numuru +{context.phone} tūlīt saņemsi 6 ciparu kodu. Ievadi to laukā. </p>
        {/* <Link to="" class="">Mainīt numuru</Link> */}
        <p>
        </p>
      </form>

    </>
  )
}


const PhoneRegister = (props) => {
  const context = useContext(CheckoutContext)

  const [error, setError] = useState(null);
  // const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [phone, setPhone] = useState(null);


  const _handleCloseMessage = (e) => {
    e.preventDefault();
    setError(null)
  }


  const registerPhone = (phone) => {

    setIsLoading(true);

    fetch(`${process.env.GATSBY_API_BASE}/verif/phone/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ phone_number: `+${phone}` })
    })
      .then(res => res.json())
      .then(
        (result) => {

          if (result.session_token) {
            setToken(result.session_token);
            setPhone(`${phone}`);
            setError(false);

            // put in context

            context.setDeviceToken(result.session_token)
            context.setPhone(`${phone}`)

          } else {
            setError("Lūdzu ievadi pareizu numuru, piemēram, 20002000.")
          }
          setIsLoading(false);

        },
        (error) => {
          setIsLoading(false);
          setError(error);
        }
      )

  }

  const verifyCode = (code) => {
    // console.log('verify', code)



    setIsLoading(true);

    fetch(`${process.env.GATSBY_API_BASE}/verif/phone/verify`, { // TODO: register
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        phone_number: `+${context.phone}`,
        session_token: context.deviceToken,
        security_code: code
      })
    })
      .then(res => res.json())
      .then(
        (result) => {
          // console.log('ok', result)
          if (result.non_field_errors) {
            setError("Ievadītais kods netika pieņemts. Pārliecinies, ka ievadīji to pareizi.")
          }
          if (!result.non_field_errors && !result.token) {
            setError("Šķiet Tev nav Heyday klienta profils. Ja vēlies pārvaldīt savu abonementu, lūdzu, sazinies ar mums")
          }
          if (result.token) {
            // process ok
            context.setSessionToken(result.token)
            setError(false);
            navigate("/manage")
          }
          setIsLoading(false);

        },
        (error) => {
          setIsLoading(false);
          setError(error);
        }
      )



  }

  return (
    <>

      {error ? <article class="message is-warning">
        <div class="message-header">
          <p>Kļūda</p>
          <button class="delete" aria-label="delete" onClick={_handleCloseMessage}></button>
        </div>
        <div class="message-body">
          {error}
        </div>
      </article> : null}

      {!context.deviceToken ? <PhoneField phoneFormRef={props.phoneFormRef} onSubmit={registerPhone} isLoading={isLoading} /> : <CodeField phoneFormRef={props.phoneFormRef} phone={phone} onSubmit={verifyCode} isLoading={isLoading} />}

    </>
  )
}

// markup
const Delivery = () => {
  const [error, setError] = useState(false);
  const phoneFormRef = React.useRef(null);

  const _handleCloseMessage = (e) => {
    e.preventDefault();
    setError(null)
  }

  const context = useContext(CheckoutContext)


  return (
    <>


        {error ? <article class="message is-warning">
          <div class="message-header">
            <p>{error}</p>
            <button class="delete" aria-label="delete" onClick={_handleCloseMessage}></button>

          </div>
        </article> : null}

        {!context.sessionToken ? <PhoneRegister phoneFormRef={phoneFormRef} /> : null}

    </>
  )
}

export default Delivery
