import * as React from "react"
import {useEffect, useState, useContext} from "react"
import Helmet from 'react-helmet'
import {Link} from 'gatsby'
import {CheckoutContext} from "../context/CheckoutContext"
import Login from '../components/Login'
import Logo from '../components/Logo'
import EndCheckout from '../components/EndCheckout.js'
import Auth from '../components/Auth'

const GoToStripe = (props) => {
  return <a href={props.url} class={props.loading ? "button is-loading is-primary" : "button is-primary"}>Mainīt esošos abonementus</a>
}




const Manage = () => {

  const [loading, setLoading] = useState(false)
  const [url, setURL] = useState(false)
  const context = useContext(CheckoutContext)

  useEffect(() => {

      if(!context.sessionToken) return;
      // get checkout session id
      setLoading(true);

      fetch(`${process.env.GATSBY_API_BASE}/verif/user/manage` , { // TODO: register
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${context.sessionToken}`
          }
        })
        .then(res => res.json())
        .then(
          (result) => {

            setURL(result.url)

            setLoading(false);
            
          },
          (error) => {
            setLoading(false);
            console.log(error)
          }
        )

  }, [context.sessionToken])

    // TODO: implement login
    

    
    return (
        <main class="">
        <section class="section">
      
        <Helmet title="Abonenta atcelšana" defer={false} />
        <Auth silent to="/manage"></Auth>

        <div class="columns is-mobile">
          <div class="column pt-0">
            <Logo />
          </div>
          <div class="column is-one-third pt-0">
            <EndCheckout />
          </div>
        </div>
      <hr />
            <h1 class="title is-2">Klientiem</h1>



      {context.sessionToken ?
      
    
      <div class="content">

          {url ? <p class="content box">
            <h2 class="title is-5">Esošie abonementi</h2>


            <hr />
          <p class="notification is-success">🚚 Ja tikko veici pirkumu, drīz saņemsi e-pastu, kurā varēsi norādīt piegādes laiku.</p>

          <hr />

            <p>Tu vari pārvaldīt savu esošo abonementu apmeklējot maksājumu paneli. Klikšķini pogu zemāk, un tiksi pārvirzīts uz Stripe.</p>
            <GoToStripe url={url} loading={loading} />
          </p> : null}

          <p class="content box">
            <h2 class="title is-5">Jauns abonements</h2>
            <p>Ja vēlies sākt abonēt ziedus vai vēlies pieteikt papildus abonementu, turpini, klikšķinot pogu zemāk.</p>
            <Link to="/subscribe" class="button is-primary">Sākt abonementu</Link>
          </p>
      </div>  

    : <div class="box"><Login /></div> }

       
       




      </section>



    </main>
  )
}

export default Manage
